import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReferenceControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { SelectItem } from 'primeng/api';

import { ProgramDayWithRecords } from './program-detail.component';

import {
    ModelsCoreProgramsProgram
} from '../../shared/swagger-codegen/models';

export interface SavedProgram {
    program: ModelsCoreProgramsProgram;
    programDaysWithRecords: ProgramDayWithRecords[];
}

@Component({
    selector: 'my-program-detail-program',
    templateUrl: './program-detail-program.component.html',
    styles: [],
    standalone: false
})
export class ProgramDetailProgramComponent extends BaseComponent implements OnInit {

    constructor(private referenceControllerProxy: ReferenceControllerProxy) {
        super();
    }

    @Input()
    program: ModelsCoreProgramsProgram;
    @Input()
    programDaysWithRecords: ProgramDayWithRecords[];
    @Output()
    save = new EventEmitter<SavedProgram>();
    periodizationPhases: SelectItem<number>[] = [];

    ngOnInit() {
        this.loadPeriodizationPhases();
    }

    saveProgramDetails() {
        this.save.emit({
            program: this.program,
            programDaysWithRecords: this.programDaysWithRecords
        });
    }

    onRowReorder() {
        this.programDaysWithRecords.forEach((record, i) => {
            record.programDay.ordinal = i;
        });
    }
    
    private loadPeriodizationPhases() {
        this.referenceControllerProxy.getPeriodizationPhases()
            .subscribe(response => {
                const phases = response.body.map(phase => {
                    return {
                        value: phase.periodizationPhaseId,
                        label: phase.name
                    };
                });

                phases.splice(0, 0, { value: -1, label: '' });

                this.periodizationPhases = phases;
            });
    }
}
