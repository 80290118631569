import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ExercisesControllerProxy, ProgramsControllerProxy, ReferenceControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { ExerciseDetailDialogService } from '../../core/exercises/detail/exercise-detail-dialog.service';
import { finalize } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

import {
    ModelsCoreProgramsProgram,
    ModelsCoreProgramsProgramDay,
    ModelsCoreProgramsProgramSession,
    ModelsCoreProgramsProgramLineItem,
    ModelsCoreProgramsProgramExercise,
    ModelsCoreProgramsProgramWeek,
    ModelsCoreSharedExercise,
    ModelsCoreWorkoutsWorkoutTypes,
    ModelsWebApiExercisesVariationsArgs,
    ModelsWebApiProgramsSaveProgramDayModel,
    ModelsWebApiProgramsSaveProgramDetailsModel,
    ModelsWebApiProgramsSaveProgramExerciseModel,
    ModelsWebApiProgramsSaveProgramLineItemModel,
    ModelsWebApiProgramsSaveProgramModel,
    ModelsWebApiProgramsSaveProgramSessionModel,
    ModelsWebApiProgramsSaveProgramWeekModel,
    ModelsWebApiProgramsSaveProgramWeekProgramExerciseModel,
    ModelsWebApiProgramsSaveProgramWeekProgramLineItemModel,
    ModelsWebApiProgramsSaveProgramWeekProgramWeekModel
} from '../../shared/swagger-codegen/models';
import { SavedProgram } from './program-detail-program.component';

export interface ProgramDayWithRecords {
    programDay: ModelsCoreProgramsProgramDay;
    records: ProgramSessionWithRecords[];
}

export interface ProgramSessionWithRecords {
    programSession: ModelsCoreProgramsProgramSession;
    records: FlattenedProgramSessionRecord[];
    isMultiWeekProgram: boolean;
}

export interface FlattenedProgramSessionRecord {
    id: number;

    programLineItem: ModelsCoreProgramsProgramLineItem;
    programExercise: ModelsCoreProgramsProgramExercise;
    programWeek: ModelsCoreProgramsProgramWeek;

    programWeeksPerProgramLineItem: number;
    programWeeksPerProgramExercise: number;

    programWeekIndexWithinProgramLineItem: number;
    programWeekIndexWithinProgramExercise: number;
}

interface RowEditModel {
    programLineItemId: number;
    programExerciseId: number;
    programWeekId: number;
    exerciseId: number;
    isWeighted: boolean;
    implementId?: number;
    superSetDisplayText: string;
    prescription: string;
    repTypeId: number;
    tempo: string;
    rest?: number;
    selectedExercise?: ModelsCoreSharedExercise;
    selectedVariationIds: number[];
}

@Component({
    selector: 'my-program-detail',
    templateUrl: './program-detail.component.html',
    styles: [],
    standalone: false
})
export class ProgramDetailComponent extends BaseComponent implements OnInit {

    constructor(
        private programsControllerProxy: ProgramsControllerProxy,
        private exercisesControllerProxy: ExercisesControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private currentRoute: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private exerciseDetailDialogService: ExerciseDetailDialogService) {
        super();
    }

    selectedTabIndex = 0;
    isLoading = false;
    showingDetails = false;
    program: ModelsCoreProgramsProgram;
    programDaysWithRecords: ProgramDayWithRecords[];
    superSets: SelectItem<string>[] = [];
    implements: SelectItem<number>[] = [];
    repTypes: SelectItem<number>[] = [];
    variations: SelectItem<number>[] = [];
    periodizationPhases: SelectItem<number>[] = [];
    exerciseSearchResults: ModelsCoreSharedExercise[] = [];
    rowEditModel?: RowEditModel;
    editingRowKeys: { [key: string]: boolean } = {};
    editingProgramDay: ProgramDayWithRecords;

    get isShowingChildComponent() {
        return this.showingDetails || this.editingProgramDay !== undefined;
    }

    private programId = 0;

    ngOnInit() {
        this.loadImplements();
        this.loadRepTypes();
        this.loadPeriodizationPhases();

        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    this.programId = parseInt(params['programId'], 10) || 0;
                    this.loadProgram();
                });
    }

    onTabChanged(e: any) {
        this.selectedTabIndex = e.index;
    }

    onEditProgramDay(day: ProgramDayWithRecords) {
        this.editingProgramDay = day;
    }

    onAddProgramDay() {
        const dayCount = this.programDaysWithRecords.length;
        const day: ProgramDayWithRecords = {
            programDay: {
                programDayId: 0,
                description: `Day ${dayCount + 1}`,
                ordinal: dayCount,
                programSessions: [
                    {
                        programSessionId: 0,
                        ordinal: 0,
                        workoutTypeId: ModelsCoreWorkoutsWorkoutTypes.StrengthTraining,
                        isManuallySelected: false
                    }
                ]
            },
            records: []
        };

        this.saveProgramDay(day);
    }

    saveProgramDay(day: ProgramDayWithRecords) {
        const programDay = day.programDay;
        const model: ModelsWebApiProgramsSaveProgramModel = {
            programDays: [{
                programDayId: programDay.programDayId,
                description: programDay.description,
                ordinal: programDay.ordinal
            }],
            programSessions: []
        };

        programDay.programSessions.forEach(programSession => {
            const sessionModel: ModelsWebApiProgramsSaveProgramSessionModel = {
                programSessionId: programSession.programSessionId,
                description: programSession.description,
                ordinal: programSession.ordinal,
                isManuallySelected: programSession.isManuallySelected,
                workoutTypeId: programSession.workoutTypeId
            };

            model.programSessions.push(sessionModel);
        });

        this.saveProgram(model);
    }

    showParentComponent() {
        this.showingDetails = false;
        this.editingProgramDay = undefined;
    }

    searchExercises(event) {
        this.exercisesControllerProxy.searchExercises({ search: event.query })
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.exerciseSearchResults = response.body;
            });
    }

    onRowEditInit(session: ProgramSessionWithRecords, record: FlattenedProgramSessionRecord, index: number) {
        const pe = record.programExercise;
        const pw = record.programWeek;

        this.loadPossibleSuperSets(session, index);

        this.rowEditModel = {
            programLineItemId: record.programLineItem.programLineItemId,
            programExerciseId: pe.programExerciseId,
            programWeekId: pw.programWeekId,
            exerciseId: pe.exercise.exerciseId,
            isWeighted: pe.exercise.isWeighted,
            implementId: pe.implement ? pe.implement.implementId : undefined,
            superSetDisplayText: record.programLineItem.superSetDisplayText,
            prescription: pw.prescription.rawText,
            repTypeId: pw.repType ? pw.repType.repTypeId : undefined,
            tempo: pw.tempo,
            rest: pw.rest,
            selectedExercise: undefined,
            selectedVariationIds: pe.variations.map(v => v.variationId)
        };

        this.exerciseSearchResults.length = 0;
        this.loadExerciseVariations();
    }

    onRowEditSave(session: ProgramSessionWithRecords, record: FlattenedProgramSessionRecord, index: number) {
        const superSetDisplayText = this.rowEditModel.superSetDisplayText;
        const superSetLetter = superSetDisplayText.charAt(0);
        const superSetNumber = superSetDisplayText.length > 1 ? parseInt(superSetDisplayText.charAt(1), 10) : 0;
        const pli = record.programLineItem;

        if(record.id > 0) {
            if(pli.superSetLetter !== superSetLetter || pli.superSetNumber !== superSetNumber) {
                const increment = superSetLetter.charCodeAt(0) - pli.superSetLetter.charCodeAt(0) + 1;
                pli.superSetLetter = superSetLetter;
                pli.superSetNumber = superSetNumber;

                if(superSetNumber === 0) {
                    this.incrementSuperSetLettersAfterIndex(session, index, increment);
                }
                else if(superSetNumber === 1) {
                    this.incrementSuperSetLettersAfterIndex(session, index, 1, superSetLetter);
                }
            }
            
            this.saveEditedRow(session, record, this.rowEditModel);
        }
        else {
            this.saveAddedRow(session, record, this.rowEditModel);
        }
    }

    onRowEditCancel(session: ProgramSessionWithRecords) {
        if(this.rowEditModel.programWeekId < 1) {
            session.records.length--;
        }

        this.rowEditModel = undefined;
    }
    
    onRowReorder(session: ProgramSessionWithRecords, e: any) {
        this.saveReorderedRows(session, e.dragIndex, e.dropIndex);
    }

    onImplementChanged() {
        this.loadExerciseVariations();
    }

    editExercise(exercise: ModelsCoreSharedExercise) {
        this.exerciseDetailDialogService.open(exercise);
    }

    deleteRow(session: ProgramSessionWithRecords, record: FlattenedProgramSessionRecord, index: number) {
        const exercise = record.programExercise.displayText;
        let header: string;
        let message: string;

        if(record.programWeeksPerProgramExercise === 1) {
            header = 'Delete Exercise?';
            message = `Are you sure you want to delete ${exercise} from this program?`;
        }
        else {
            header = 'Delete Week?';
            message = `Are you sure you want to delete Week ${record.programWeek.ordinal + 1} of ${exercise} from this program?`;
        }
        
        this.confirmationService.confirm(
            {
                key: 'program-detail-component',
                header: header,
                message: message,
                accept: () => {
                    this.isLoading = true;
                    this.programsControllerProxy.deleteProgramWeek(this.programId, record.programWeek.programWeekId)
                        .pipe(
                            finalize(
                                () => {
                                    this.isLoading = false;
                                }),
                            this.takeUntilUnsubscribed())
                        .subscribe(() => {
                            session.records.splice(index, 1);
                            this.loadProgram();
                        });
                }
            });
    }

    exerciseSelected() {
        this.rowEditModel.exerciseId = this.rowEditModel.selectedExercise.exerciseId;
        this.rowEditModel.isWeighted = this.rowEditModel.selectedExercise.isWeighted;
        this.loadExerciseVariations();
    }

    addProgramLineItem(session: ProgramSessionWithRecords) {
        const programWeek: ModelsCoreProgramsProgramWeek = {
            programWeekId: 0,
            ordinal: 0,
            prescription: {
                displayText: '',
                rawText: '',
                repTypeId: 1,
                sets: []
            },
            repType: {
                repTypeId: 1,
                name: 'Reps'
            },
            tempo: null,
            rest: null
        };
        const programExercise: ModelsCoreProgramsProgramExercise = {
            programExerciseId: 0,
            displayText: '',
            implement: null,
            ordinal: 0,
            isWeighted: true,
            exercise: {
                exerciseId: 0,
                name: '',
                exerciseTypeId: 1,
                isWeighted: true
            },
            programWeeks: [programWeek],
            variations: []
        };
        const programLineItem: ModelsCoreProgramsProgramLineItem = {
            programLineItemId: 0,
            superSetLetter: 'A',
            superSetNumber: 0,
            superSetDisplayText: 'A',
            ordinal: 0,
            programExercises: [programExercise]
        };

        const record = this.flattenProgramWeeks(
            programLineItem,
            programExercise,
            0
        )[0];

        if(session.records.length > 0) {
            const previous = session.records[session.records.length - 1].programLineItem;
            programLineItem.superSetLetter = String.fromCharCode(previous.superSetLetter.charCodeAt(0) + 1);
            programLineItem.superSetDisplayText = programLineItem.superSetLetter;
        }

        session.records.push(record);
        this.onRowEditInit(session, record, session.records.length - 1);
        this.editingRowKeys['0'] = true;
    }

    saveProgramDetails(savedProgram: SavedProgram) {
        const program = savedProgram.program;
        const periodizationPhaseId = program.periodizationPhase.periodizationPhaseId;
        const details: ModelsWebApiProgramsSaveProgramDetailsModel = {
            programId: this.programId,
            name: program.name,
            description: program.description,
            source: program.source,
            periodizationPhaseId: periodizationPhaseId > 0 ? periodizationPhaseId : undefined
        };
        const model: ModelsWebApiProgramsSaveProgramModel = {
            details: details,
            programDays: []
        };

        savedProgram.programDaysWithRecords.forEach(dayRecord => {
            const programDay = dayRecord.programDay;
            const dayModel: ModelsWebApiProgramsSaveProgramDayModel = {
                programDayId: programDay.programDayId,
                description: programDay.description,
                ordinal: programDay.ordinal
            };

            model.programDays.push(dayModel);
        });

        this.saveProgram(model);
    }

    private loadExerciseVariations() {
        const args: ModelsWebApiExercisesVariationsArgs = {
            implementId: this.rowEditModel.implementId > 0 ? this.rowEditModel.implementId : undefined
        };

        this.exercisesControllerProxy.getExerciseVariations(this.rowEditModel.exerciseId, args)
            .subscribe(response => {
                this.variations = response.body.map(variation => {
                    return {
                        value: variation.variationId,
                        label: variation.description
                    };
                });

                for(let i = this.rowEditModel.selectedVariationIds.length - 1; i >= 0; i--) {
                    const variationId = this.rowEditModel.selectedVariationIds[i];

                    if(!this.variations.find(si => si.value === variationId)) {
                        this.rowEditModel.selectedVariationIds.splice(i, 1);
                    }
                }
            });
    }

    private loadImplements() {
        this.referenceControllerProxy.getImplements()
            .subscribe(response => {
                this.implements = response.body.map(implement => {
                    return {
                        value: implement.implementId,
                        label: implement.name
                    };
                });

                this.implements.splice(0, 0, { value: -1, label: 'N/A' });
            });
    }

    private loadRepTypes() {
        this.referenceControllerProxy.getRepTypes()
            .subscribe(response => {
                this.repTypes = response.body.map(repType => {
                    return {
                        value: repType.repTypeId,
                        label: repType.name
                    };
                });
            });
    }
    
    private loadPeriodizationPhases() {
        this.referenceControllerProxy.getPeriodizationPhases()
            .subscribe(response => {
                const phases = response.body.map(phase => {
                    return {
                        value: phase.periodizationPhaseId,
                        label: phase.name
                    };
                });

                phases.splice(0, 0, { value: -1, label: '' });

                this.periodizationPhases = phases;
            });
    }
    
    private loadProgram() {
        this.isLoading = true;
        this.programsControllerProxy.getProgram(this.programId)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(response => this.processProgramData(response.body));
    }

    private processProgramData(program: ModelsCoreProgramsProgram) {
        const programDaysWithRecords: ProgramDayWithRecords[] = [];

        if(!program.periodizationPhase) {
            program.periodizationPhase = {} as any;
        }

        program.programDays.forEach(programDay => {
            const day: ProgramDayWithRecords = {
                programDay: programDay,
                records: []
            };
            programDaysWithRecords.push(day);

            programDay.programSessions.forEach(programSession => {
                const session: ProgramSessionWithRecords = {
                    programSession: programSession,
                    records: [],
                    isMultiWeekProgram: false
                };
                day.records.push(session);
                
                programSession.programLineItems.forEach(programLineItem => {
                    const recordsForProgramLineItem: FlattenedProgramSessionRecord[] = [];
                    let programWeeksPerProgramLineItem = 0;

                    programLineItem.programExercises.forEach(programExercise => {
                        const weeks = this.flattenProgramWeeks(
                            programLineItem,
                            programExercise,
                            programWeeksPerProgramLineItem
                        );
                        
                        recordsForProgramLineItem.push(...weeks);
                        programWeeksPerProgramLineItem += weeks.length;

                        if(weeks.length > 1) {
                            session.isMultiWeekProgram = true;
                        }
                    });

                    recordsForProgramLineItem.forEach(record => {
                        record.programWeeksPerProgramLineItem = programWeeksPerProgramLineItem;
                    });

                    session.records.push(...recordsForProgramLineItem);
                });
            });
        });

        this.programDaysWithRecords = programDaysWithRecords;
        this.program = program;
    }

    private flattenProgramWeeks(
        programLineItem: ModelsCoreProgramsProgramLineItem,
        programExercise: ModelsCoreProgramsProgramExercise,
        programLineItemRelativeIndex: number) {

        return programExercise.programWeeks.map((programWeek, i) => {
            const record: FlattenedProgramSessionRecord = {
                id: programWeek.programWeekId,

                programLineItem: programLineItem,
                programExercise: programExercise,
                programWeek: programWeek,

                programWeeksPerProgramLineItem: 0,
                programWeeksPerProgramExercise: programExercise.programWeeks.length,

                programWeekIndexWithinProgramLineItem: i + programLineItemRelativeIndex,
                programWeekIndexWithinProgramExercise: i
            };

            return record;
        });
    }

    private saveEditedRow(session: ProgramSessionWithRecords, record: FlattenedProgramSessionRecord, updateModel: RowEditModel) {
        const programExercise: ModelsWebApiProgramsSaveProgramExerciseModel = {
            programExerciseId: record.programExercise.programExerciseId,
            exerciseId: updateModel.exerciseId,
            isWeighted: updateModel.isWeighted,
            implementId: updateModel.implementId < 0 ? undefined : updateModel.implementId,
            ordinal: record.programExercise.ordinal,
            variationIds: updateModel.selectedVariationIds
        };
        const programWeek: ModelsWebApiProgramsSaveProgramWeekModel = {
            programWeekId: record.programWeek.programWeekId,
            prescription: updateModel.prescription,
            repTypeId: updateModel.repTypeId,
            tempo: updateModel.tempo,
            rest: updateModel.rest
        };
        const programLineItems = this.getReorderedProgramLineItemsToSave(session);
        const model: ModelsWebApiProgramsSaveProgramModel = {
            programExercises: [programExercise],
            programWeeks: [programWeek],
            programLineItems: programLineItems
        };

        this.saveProgram(model);
    }

    private saveAddedRow(session: ProgramSessionWithRecords, record: FlattenedProgramSessionRecord, updateModel: RowEditModel) {
        const programLineItem: ModelsWebApiProgramsSaveProgramWeekProgramLineItemModel = {
            programLineItemId: record.programLineItem.programLineItemId,
            programSessionId: session.programSession.programSessionId,
            superSetLetter: record.programLineItem.superSetLetter,
            superSetNumber: record.programLineItem.superSetNumber,
            ordinal: record.programLineItem.ordinal
        };
        const programExercise: ModelsWebApiProgramsSaveProgramWeekProgramExerciseModel = {
            programExerciseId: record.programExercise.programExerciseId,
            exerciseId: updateModel.exerciseId,
            isWeighted: updateModel.isWeighted,
            implementId: updateModel.implementId < 0 ? undefined : updateModel.implementId,
            ordinal: record.programExercise.ordinal,
            programLineItem: programLineItem,
            variationIds: updateModel.selectedVariationIds
        };
        const model: ModelsWebApiProgramsSaveProgramWeekProgramWeekModel = {
            programWeekId: record.programWeek.programWeekId,
            prescription: updateModel.prescription,
            repTypeId: updateModel.repTypeId,
            tempo: updateModel.tempo,
            rest: updateModel.rest,
            programExercise: programExercise
        };

        this.saveProgramWeek(model);
    }


    private saveReorderedRows(session: ProgramSessionWithRecords, fromIndex: number, toIndex: number) {
        this.inferSuperSetAtIndex(session, fromIndex);
        this.inferSuperSetAtIndex(session, toIndex);

        const programLineItems = this.getReorderedProgramLineItemsToSave(session);
        const model: ModelsWebApiProgramsSaveProgramModel = {
            programLineItems: programLineItems
        };

        this.saveProgram(model);
    }

    private loadPossibleSuperSets(session: ProgramSessionWithRecords, forIndex: number) {
        const superSets: string[] = [];
        const current = session.records[forIndex].programLineItem;
        const previous = forIndex > 0 ? session.records[forIndex - 1].programLineItem : undefined;
        const next = forIndex + 1 < session.records.length ? session.records[forIndex + 1].programLineItem : undefined;

        if(current.superSetNumber <= 1 && previous) {
            // Join the previous super set (e.g. B1 becomes A3)
            const joinPreviousSuperSet = `${previous.superSetLetter}${previous.superSetNumber > 0 ? previous.superSetNumber + 1 : 2}`;
            superSets.push(joinPreviousSuperSet);
        }

        if(current.superSetNumber === 1) {
            // Become a solo set (e.g. B1 becomes B)
            superSets.push(`${current.superSetLetter}`);
        }
        
        // Stay the same (e.g. B1 remains B1)
        superSets.push(`${current.superSetLetter}${current.superSetNumber > 0 ? current.superSetNumber : ''}`);
        
        if(current.superSetNumber > 1) {
            // Join the next super set (e.g. B3 becomes C or C1)
            const startNextSuperSet = String.fromCharCode(current.superSetLetter.charCodeAt(0) + 1);

            if(!next) {
                superSets.push(`${startNextSuperSet}`);
            }
            else if(current.superSetNumber > 0) {
                superSets.push(`${startNextSuperSet}`, `${startNextSuperSet}1`);
            }
            else {
                superSets.push(`${startNextSuperSet}1`);
            }
        }

        this.superSets = superSets.map(s => ({ value: s, label: s }));
    }

    private incrementSuperSetLettersAfterIndex(
        session: ProgramSessionWithRecords,
        index: number,
        increment: number,
        stopAtLetter = '') {

        for(let i = index + 1; i < session.records.length; i++) {
            const pli = session.records[i].programLineItem;

            if(pli.superSetLetter === stopAtLetter) {
                break;
            }

            pli.superSetLetter = String.fromCharCode(pli.superSetLetter.charCodeAt(0) + increment);
        }
    }

    private inferSuperSetAtIndex(session: ProgramSessionWithRecords, index: number) {
        const records = session.records;

        if(index < 0 || index >= records.length) {
            return;
        }

        const previous = index > 0 ? records[index - 1].programLineItem : undefined;
        const current = records[index].programLineItem;
        const next = index < records.length - 1 ? records[index + 1].programLineItem : undefined;

        if(index === 0 || records.length === 1) {
            current.superSetLetter = 'A';
            current.superSetNumber = next && next.superSetLetter === 'A' ? 1 : 0;
        }
        else if(previous && previous.superSetNumber > 0) {
            current.superSetLetter = previous.superSetLetter;
            current.superSetNumber = previous.superSetNumber + 1;
        }
        else if(next && next.superSetNumber > 0) {
            current.superSetLetter = next.superSetLetter;
            current.superSetNumber = 1;
        }
        else if(previous) {
            let previousLetter = '';
            let currentLetter = String.fromCharCode(previous.superSetLetter.charCodeAt(0) + 1);
            current.superSetLetter = currentLetter;
            current.superSetNumber = 0;

            for(let i = index + 1; i < records.length; i++) {
                const record = records[i].programLineItem;

                if(record.superSetLetter !== previousLetter) {
                    currentLetter = String.fromCharCode(previousLetter.charCodeAt(0) + 1);
                    previousLetter = currentLetter;
                }

                record.superSetLetter = currentLetter;
            }
        }
    }

    private saveProgram(model: ModelsWebApiProgramsSaveProgramModel) {
        this.isLoading = true;
        this.programsControllerProxy.saveProgram(this.programId, model)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.rowEditModel = undefined;
                this.showParentComponent();
                this.loadProgram();
            });
    }

    private saveProgramWeek(model: ModelsWebApiProgramsSaveProgramWeekProgramWeekModel) {
        this.isLoading = true;
        this.programsControllerProxy.saveProgramWeek(this.programId, model)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.rowEditModel = undefined;
                this.loadProgram();
            });
    }

    private getReorderedProgramLineItemsToSave(session: ProgramSessionWithRecords) {
        const models: ModelsWebApiProgramsSaveProgramLineItemModel[] = [];

        let ordinal = 0;
        let previousUnmodifiedSuperSetLetter = '';
        let superSetLetter = String.fromCharCode('A'.charCodeAt(0) - 1);
        let superSetNumber = 0;
        
        session.records.forEach((record, i) => {
            const pli = record.programLineItem;
            
            if(pli.superSetLetter !== previousUnmodifiedSuperSetLetter) {
                previousUnmodifiedSuperSetLetter = pli.superSetLetter;
                superSetLetter = String.fromCharCode(superSetLetter.charCodeAt(0) + 1);
                
                const next = i + 1 < session.records.length ? session.records[i + 1].programLineItem : undefined;
                superSetNumber = next && next.superSetLetter === pli.superSetLetter ? 1 : 0;
            }
            else {
                superSetNumber++;
            }

            pli.superSetLetter = superSetLetter;
            pli.superSetNumber = superSetNumber;

            const superSetDisplayText = pli.superSetNumber > 0 ? `${pli.superSetLetter}${pli.superSetNumber}` : pli.superSetLetter;

            if(pli.superSetDisplayText !== superSetDisplayText || pli.ordinal !== ordinal) {
                pli.superSetDisplayText = superSetDisplayText;
                pli.ordinal = ordinal;

                models.push({
                    programLineItemId: pli.programLineItemId,
                    superSetLetter: pli.superSetLetter,
                    superSetNumber: pli.superSetNumber,
                    ordinal: pli.ordinal
                });
            }

            ordinal++;
        });

        return models;
    }
}
