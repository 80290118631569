import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReferenceControllerProxy } from '../../shared/server-proxies';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { SelectItem } from 'primeng/api';

import { ProgramDayWithRecords } from './program-detail.component';

import {
    ModelsCoreProgramsProgramSession
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-program-detail-day',
    templateUrl: './program-detail-day.component.html',
    styles: [],
    standalone: false
})
export class ProgramDetailDayComponent extends BaseComponent implements OnInit {

    constructor(private referenceControllerProxy: ReferenceControllerProxy) {
        super();
    }

    @Input()
    day: ProgramDayWithRecords;
    @Output()
    save = new EventEmitter<ProgramDayWithRecords>();
    workoutTypes: SelectItem<number>[] = [];
    isLoading = false;

    ngOnInit() {
        this.loadWorkoutTypes();
    }
    
    saveProgramDay() {
        this.save.emit(this.day);
    }

    getSessionDescription(session: ModelsCoreProgramsProgramSession, rowIndex: number) {
        return session.description || `Session ${rowIndex + 1}`;
    }

    private loadWorkoutTypes() {
        this.referenceControllerProxy.getWorkoutTypes()
            .subscribe(response => {
                this.workoutTypes = response.body.map(repType => {
                    return {
                        value: repType.workoutTypeId,
                        label: repType.name
                    };
                });
            });
    }
}
