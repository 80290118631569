import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { UsersControllerProxy } from '../../shared/server-proxies/users-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';

import {
    ModelsCoreUsersEquipmentTypes,
    ModelsCoreUsersTraineeEquipment
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-plate-calculator',
    templateUrl: './plate-calculator.component.html',
    standalone: false
})
export class PlateCalculatorComponent extends BaseComponent implements OnInit {
    constructor(private usersControllerProxy: UsersControllerProxy) {
        super();
    }

    selectedBar: ModelsCoreUsersTraineeEquipment;
    barItems: SelectItem[];
    private bars: ModelsCoreUsersTraineeEquipment[] = [];
    private plates: ModelsCoreUsersTraineeEquipment[] = [];

    @Input()
    weight: number;

    ngOnInit() {
        this.usersControllerProxy.getEquipment()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                const equipment = response.body;
                this.bars = equipment.filter(e => e.equipmentType.equipmentTypeId === ModelsCoreUsersEquipmentTypes.Bar);
                this.plates = equipment.filter(e => e.equipmentType.equipmentTypeId === ModelsCoreUsersEquipmentTypes.WeightPlate);

                this.barItems = this.bars.map(bar => {
                    return {
                        label: bar.name,
                        value: bar
                    };
                });
                
                if(this.barItems.length > 0) {
                    this.selectedBar = this.barItems[0].value;
                    this.ensureWeightIsAtLeastSelectedBarWeight();
                }
            });
    }

    barChanged() {
        this.ensureWeightIsAtLeastSelectedBarWeight();
    }

    getRequiredEquipment() {
        let remainingWeight = this.weight;
        const requiredEquipment: string[] = [];

        if(remainingWeight <= 0) {
            return requiredEquipment;
        }

        if(this.selectedBar) {
            remainingWeight -= this.selectedBar.weight;
        }
        else {
            requiredEquipment.push('Olympic Bar');
            remainingWeight -= 45;
        }

        for (let i = 0; remainingWeight > 0 && i < this.plates.length; i++) {
            const current = this.plates[i];
            let currentUsefulPlates = 0;

            while (currentUsefulPlates < (current.number / 2) && remainingWeight >= (current.weight * 2)) {
                remainingWeight -= (current.weight * 2);
                currentUsefulPlates++;
            }

            if(currentUsefulPlates > 0) {
                requiredEquipment.push(`${currentUsefulPlates} x ${current.name}`);
            }
        }

        if(remainingWeight > 0) {
            requiredEquipment.push(`Total Weight: ${this.weight - remainingWeight}`);
        }

        return requiredEquipment;
    }

    private ensureWeightIsAtLeastSelectedBarWeight() {
        if(this.selectedBar.weight > this.weight) {
            this.weight = this.selectedBar.weight;
        }
    }
}
