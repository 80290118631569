import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '../../shared/auth/session.service';
import { InstrumentationService } from '../../shared/core/instrumentation.service';
import { AuthenticationControllerProxy } from '../../shared/server-proxies/authentication-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { Message } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import {
    ModelsAuthenticationAuthenticateAuthenticateResponse,
    ModelsWebApiAuthenticationLoginModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-login',
    templateUrl: './login.component.html',
    standalone: false
})
export class LoginComponent extends BaseComponent implements OnInit {
    constructor(
        private authenticationControllerProxy: AuthenticationControllerProxy,
        private instrumentationService: InstrumentationService,
        private session: SessionService,
        private router: Router) {

        super();
        this.messages = [];
    }

    @ViewChild('loginForm', { static: true })
    loginForm: NgForm;
    credentials: ModelsWebApiAuthenticationLoginModel = {
        email: '',
        password: '',
        rememberMe: false
    };
    hasRememberMe: boolean;
    messages: Message[];
    isLoading: boolean;
    isLoggingInWithToken: boolean;

    ngOnInit() {
        const token = this.session.getPersistedToken();

        if(token) {
            this.loginWithToken(token);
        }
    }

    login() {
        this.messages.length = 0;
        this.isLoading = true;
        this.authenticationControllerProxy.login(this.credentials)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed()
            )
            .subscribe(
                response => {
                    this.messages = [{ severity: 'info', summary: '', detail: 'Login suceeded.' }];
                    this.handleSuccessfulLogin(response.body, this.credentials.rememberMe);
                },
                error => {
                    if(error.status === 401) {
                        this.messages = [{ severity: 'warn', summary: '', detail: 'Login failed.' }];
                    }
                    else {
                        this.messages = [{ severity: 'error', summary: '', detail: 'An error occurred.' }];
                    }
                });
    }

    getEmailAutoFocus() {
        return this.hasRememberMe ? null : 'autofocus';
    }

    getPasswordAutoFocus() {
        return this.hasRememberMe ? 'autofocus' : null;
    }

    private loginWithToken(token: string) {
        this.isLoading = true;
        this.isLoggingInWithToken = true;

        this.authenticationControllerProxy.loginWithToken({ token: token })
            .pipe(
                finalize(() => this.isLoading = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.handleSuccessfulLogin(response.body, true);
                },
                error => {
                    if(error.status === 401) {
                        this.session.disposeSession();
                    }

                    this.isLoggingInWithToken = false;
                });
    }

    private handleSuccessfulLogin(result: ModelsAuthenticationAuthenticateAuthenticateResponse, rememberMe: boolean) {
        this.session.initializeSession(result.user, result.token.value, rememberMe);
        this.instrumentationService.setUser(result.user);
        this.router.navigateByUrl('/');
    }
}
