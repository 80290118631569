import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TimeSpan } from '../../shared/util';
import { SessionService } from '../../shared/auth/session.service';
import { WorkoutsControllerProxy } from '../../shared/server-proxies/workouts-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { WorkoutEntryState } from './workout-entry-state';
import { timer as observableTimer } from 'rxjs';
import { finalize } from 'rxjs/operators';

import {
    ModelsWebApiWorkoutsSaveWorkoutLogModel,
    ModelsWebApiWorkoutsSaveWorkoutModel,
    ModelsCoreWorkoutsWorkout,
    ModelsCoreSharedExerciseTypes,
    ModelsCoreSharedRepTypes
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-workout-entry',
    templateUrl: './workout-entry.component.html',
    styleUrls: [
        './workout-entry.component.css'
    ],
    standalone: false
})
export class WorkoutEntryComponent extends BaseComponent implements OnInit {
    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute,
        private workoutsControllerProxy: WorkoutsControllerProxy,
        private session: SessionService) {
        super();
        this.remainingRest = null;
    }

    workout: ModelsCoreWorkoutsWorkout;
    state: WorkoutEntryState;
    workoutElapsedTime: string;
    remainingRest: number;
    isSaving: boolean;
    errorMessage: string;
    isTrackingRepsInReserve: boolean;
    isTrackingReps: boolean;
    isTrackingWeight: boolean;
    repsName = 'Reps';

    @ViewChild('workoutForm', { static: true }) workoutForm: NgForm;
    private restTimerSubscription: Subscription;
    // private alarm: HTMLAudioElement;

    ngOnInit() {
        // this.alarm = new Audio('/assets/audio/ringing-clock.mp3');

        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const workoutId = parseInt(params['workoutId'] || '0', 10);
                    const index = parseInt(params['index'] || '-1', 10);

                    if(!this.workout || this.workout.workoutId !== workoutId) {
                        this.workoutsControllerProxy.getWorkout(workoutId)
                            .pipe(this.takeUntilUnsubscribed())
                            .subscribe(
                                response => {
                                    this.workout = response.body;
                                    this.state = new WorkoutEntryState(this.workout, index);
                                    this.loadWorkoutDetail();
                                    this.startWorkoutTimer();
                                });
                    }
                    else if(this.state) {
                        this.state.setIndex(index);
                        this.loadWorkoutDetail();
                    }
                });
    }

    getExerciseHeader() {
        if(this.state) {
            const detail = this.state.getCurrentWorkoutDetail();

            if(detail) {
                const superSet = detail.superSetDisplayText;
                const exercise = detail.exerciseDisplayText;
                const currentSet = this.state.getCurrentSetForExercise();
                const totalSets = this.state.getTotalSetsForExercise();

                return `${superSet}: ${exercise} [${currentSet} of ${totalSets}]`;
            }
        }

        return '';
    }

    getUpNextDescription() {
        let description = '';

        if(this.state) {
            const currentDetail = this.state.getCurrentWorkoutDetail();
            const nextDetail = this.state.getNextWorkoutDetail();
            const nextIndices = this.state.getNextTraversalIndices();

            if(nextDetail) {
                if(currentDetail.exercise.exerciseId !== nextDetail.exercise.exerciseId) {
                    description = `${nextDetail.exerciseDisplayText} - Set ${(nextIndices.setIndex + 1)} of `
                        + `${ nextDetail.sets.length }`;
                }
                else {
                    description = `Set ${(nextIndices.setIndex + 1)} of ${nextDetail.sets.length}`;
                }
            }
        }

        return description;
    }

    previous() {
        this.errorMessage = null;

        if(this.state) {
            if(this.state.isResting() && this.state.tryPrevious()) {
                this.navigateToSelectedIndex();
            }
            else {
                this.saveSet(() => this.handleNavigation(() => this.state.tryPrevious()));
            }
        }
    }

    next() {
        this.errorMessage = null;

        if(this.state) {
            if(this.state.isResting() && this.state.tryNext()) {
                this.navigateToSelectedIndex();
            }
            else {
                this.saveSet(() => this.handleNavigation(() => this.state.tryNext()));
            }
        }
    }

    complete() {
        this.isSaving = true;
        this.workout.completed = new Date();

        const model: ModelsWebApiWorkoutsSaveWorkoutModel = {
            workoutId: this.workout.workoutId,
            bodyWeight: this.workout.bodyWeight,
            notes: this.workout.notes,
            completed: this.workout.completed
        };

        this.saveSet(() => {
            this.workoutsControllerProxy.saveWorkout(model)
                .pipe(
                    finalize(
                        () => {
                            this.isSaving = false;
                        }),
                    this.takeUntilUnsubscribed())
                .subscribe(
                    response => {
                        this.router.navigate(['/workouts', this.workout.workoutId, 'complete']);
                        return response;
                    });
        });
    }

    onFocusReps() {
        if(this.state) {
            this.state.initializeReps();
        }
    }

    onFocusWeight() {
        if(this.state) {
            this.state.initializeWeight();
        }
    }

    onFocusRepsInReserve() {
        if(this.state) {
            this.state.initializeRepsInReserve();
        }
    }

    shouldShowRestCounter() {
        return this.state && this.state.isResting() && this.remainingRest > 0;
    }

    shouldShowRestComplete() {
        return this.state && this.state.isResting() && this.remainingRest === 0;
    }

    shouldShowElapsedTime() {
        return this.workoutElapsedTime && !this.workout.completed;
    }

    private saveSet(done: () => void = () => { }) {
        this.isSaving = true;

        const workout = this.workout;
        const workoutLog = this.state.getCurrentWorkoutLog();
        const indices = this.state.getCurrentTraversalIndices();

        const currentSet = this.state.getCurrentSet();
        const weight = this.isTrackingWeight ? workoutLog.weight : 0;
        const reps = this.isTrackingReps ? workoutLog.reps : ((currentSet.toReps || currentSet.fromReps) || 1);
        const repsInReserve = this.isTrackingRepsInReserve ? workoutLog.repsInReserve : null;

        const model: ModelsWebApiWorkoutsSaveWorkoutLogModel = {
            workoutLogId: workoutLog.workoutLogId,
            weight: weight,
            reps: reps,
            repsInReserve: repsInReserve,
            workoutDetailId: workoutLog.workoutDetailId
        };

        return this.workoutsControllerProxy.saveWorkoutLog(workout.workoutId, model)
            .pipe(
                finalize(
                    () => {
                        this.isSaving = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.workout.workoutDetails[indices.detailIndex].workoutLogs[indices.setIndex] = response.body.workoutLog;
                    this.workout.started = response.body.workoutStarted;
                    this.workout.completed = response.body.workoutCompleted;

                    done();

                    return response;
                },
                errorResponse => {
                    this.errorMessage = errorResponse.statusText;
                    return errorResponse;
                }
            );
    }

    private handleNavigation(tryChangingIndex: () => boolean) {
        if(tryChangingIndex()) {
            this.navigateToSelectedIndex();
        }
        else if(this.state.isResting()) {
            this.startRestTimer();
        }
    }

    private navigateToSelectedIndex() {
        const optionalParams = {
            index: this.state.getIndex()
        };

        this.stopRestTimer();
        // this.alarm.pause();
        this.workoutForm.resetForm();
        this.router.navigate(['workouts', this.workout.workoutId, optionalParams]);
    }

    private startWorkoutTimer() {
        const ts = new TimeSpan();
        const created = new Date(this.workout.created);

        observableTimer(0, 10000)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                const now = new Date();
                ts.subtractDates(created, now);
                this.workoutElapsedTime = ts.toString();
            });
    }

    private startRestTimer() {
        const elapsed = new TimeSpan();
        const start = new Date();
        const rest = this.state.getCurrentWorkoutDetail().rest;

        this.remainingRest = rest;
        this.restTimerSubscription = observableTimer(100, 100)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(() => {
                const now = new Date();
                elapsed.subtractDates(start, now);

                const remaining = rest - elapsed.totalSeconds;
                this.remainingRest = Math.round(remaining * 100) / 100;

                if(this.remainingRest <= 0) {
                    this.restTimerSubscription.unsubscribe();
                    this.remainingRest = 0;
                    // this.alarm.play();
                }
            });
    }

    private stopRestTimer() {
        if(this.restTimerSubscription) {
            this.restTimerSubscription.unsubscribe();
            this.restTimerSubscription = null;
        }

        this.remainingRest = null;
    }

    private loadWorkoutDetail() {
        const workoutDetail = this.state.getCurrentWorkoutDetail();
        this.isTrackingReps = workoutDetail.exercise.exerciseTypeId === ModelsCoreSharedExerciseTypes.Strength;
        this.isTrackingWeight = workoutDetail.isWeighted;
        this.isTrackingRepsInReserve = this.isTrackingReps
            && workoutDetail.repType.repTypeId !== ModelsCoreSharedRepTypes.Minutes
            && workoutDetail.repType.repTypeId !== ModelsCoreSharedRepTypes.Seconds
            && this.session.getUser().trackRepsInReserve;

        switch(workoutDetail.repType.repTypeId) {
        case ModelsCoreSharedRepTypes.Seconds:
            this.repsName = 'Seconds';
            break;
        case ModelsCoreSharedRepTypes.Minutes:
            this.repsName = 'Minutes';
            break;
        default:
            this.repsName = 'Reps';
            break;
        }
    }
}
