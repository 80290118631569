import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsersControllerProxy } from '../../shared/server-proxies/users-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { Message } from 'primeng/api';
import { finalize } from 'rxjs/operators';

import {
    ModelsCoreUsersUserAccount,
    ModelsWebApiUsersSaveProfileModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-profile',
    templateUrl: './profile.component.html',
    standalone: false
})
export class ProfileComponent extends BaseComponent implements OnInit {
    constructor(private usersControllerProxy: UsersControllerProxy) {
        super();
        this.profile = {} as ModelsCoreUsersUserAccount;
        this.messages = [];
    }

    @ViewChild('profileForm', { static: true })
    loginForm: NgForm;
    profile: ModelsCoreUsersUserAccount;
    messages: Message[];
    isSaving: boolean;

    ngOnInit() {
        this.usersControllerProxy.getProfile()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                response => this.profile = response.body,
                () => this.messages.push({ severity: 'error', summary: '', detail: 'An error occurred.' }));
    }

    save() {
        this.isSaving = true;
        this.usersControllerProxy.saveProfile(this.profile as ModelsWebApiUsersSaveProfileModel)
            .pipe(
                finalize(() => this.isSaving = false),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                },
                () => this.messages.push({ severity: 'error', summary: '', detail: 'An error occurred.' }));
    }

    enableOAuth(name: string) {
        window.location.href = `/oauth2?authscheme=${name}&returnUri=/profile`;
    }
}
